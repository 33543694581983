// RemoteConfigKeys.js
'use strict';

export const SENTRY_LOG_LEVEL = 'SENTRY_LOG_LEVEL';
export const SENTRY_SAMPLE_RATE = 'SENTRY_SAMPLE_RATE';
export const SENTRY_TRACE_SAMPLE_RATE = 'SENTRY_TRACE_SAMPLE_RATE';
export const SENTRY_REPLAY_SESSION_SAMPLE_RATE =
  'SENTRY_REPLAY_SESSION_SAMPLE_RATE';
export const SENTRY_REPLAY_ON_ERROR_SAMPLE_RATE =
  'SENTRY_REPLAY_ON_ERROR_SAMPLE_RATE';
export const SENTRY_BYTES_LIMIT_FOR_EXTRA_DATA =
  'SENTRY_BYTES_LIMIT_FOR_EXTRA_DATA';

export const API_URL_PREFIX = 'API_URL_PREFIX';
export const WATCH_URL_PREFIX = 'WATCH_URL_PREFIX';
export const PUBLIC_URL_PREFIX = 'PUBLIC_URL_PREFIX';
export const PUBLIC_ENCRYPTED_URL_PREFIX = 'PUBLIC_ENCRYPTED_URL_PREFIX';
export const RSS_FEED_URL_PREFIX = 'RSS_FEED_URL_PREFIX';
export const SSR_API_URL_PREFIX = 'SSR_API_URL_PREFIX';
export const PUBLIC_MESSAGE_URL_PATH = 'PUBLIC_MESSAGE_URL_PATH';
export const PUBLIC_PRODUCT_URL_PATH = 'PUBLIC_PRODUCT_URL_PATH';
export const PUBLIC_BADGE_URL_PATH = 'PUBLIC_BADGE_URL_PATH';
export const PUBLIC_USER_URL_PATH = 'PUBLIC_USER_URL_PATH';
export const PUBLIC_LEADERBOARD_URL_PATH = 'PUBLIC_LEADERBOARD_URL_PATH';
export const PUBLIC_SHOP_URL_PATH = 'PUBLIC_SHOP_URL_PATH';
export const PUBLIC_LOGO_URL_PATH = 'PUBLIC_LOGO_URL_PATH';
export const PUBLIC_APP_URL_PATH = 'PUBLIC_APP_URL_PATH';
export const PUBLIC_URL_ENCRYPT_KEYS = 'PUBLIC_URL_ENCRYPT_KEYS';

export const DISCOVER_CATEGORIES = 'DISCOVER_CATEGORIES';
export const HOME_FEATURE_CATEGORIES = 'HOME_FEATURE_CATEGORIES';
export const HOME_VIDEO_CATEGORIES = 'HOME_VIDEO_CATEGORIES';
export const HOME_BANNERS = 'HOME_BANNERS';
export const HOME_BANNER_PADDINGS = 'HOME_BANNER_PADDINGS';
export const VIDEO_BANNERS = 'VIDEO_BANNERS';
export const VIDEO_BANNER_PADDINGS = 'VIDEO_BANNER_PADDINGS';

export const FAQ_URL_TEMPLATE = 'FAQ_URL_TEMPLATE';
export const FAQ_SUPPORTED_LANGUAGES = 'FAQ_SUPPORTED_LANGUAGES';

export const SCHAT_UNIVERSAL_DOMAIN = 'SCHAT_UNIVERSAL_DOMAIN';
export const SCHAT_IOS_BUNDLE_ID = 'SCHAT_IOS_BUNDLE_ID';
export const SCHAT_IOS_STORE_ID = 'SCHAT_IOS_STORE_ID';
export const SWAG_IOS_STORE_ID = 'SWAG_IOS_STORE_ID';
export const SWAG_IOS_BUNDLE_ID = 'SWAG_IOS_BUNDLE_ID';
export const SWAG_UNIVERSAL_DOMAIN = 'SWAG_UNIVERSAL_DOMAIN';
export const SWAG_ANDROID_PACKAGE_NAME = 'SWAG_ANDROID_PACKAGE_NAME';
export const ANDROID_MINIMUM_VERSION = 'ANDROID_MINIMUM_VERSION';
export const ANDROID_DOWNLOAD_APK = 'ANDROID_DOWNLOAD_APK';

export const ANDROID_DOWNLOAD_VERSION = 'ANDROID_DOWNLOAD_VERSION';
export const ANDROID_SUPPORT_VERSION = 'ANDROID_SUPPORT_VERSION';

export const IOS_SUPPORT_VERSION = 'IOS_SUPPORT_VERSION';
export const IPHONE_DISTRIBUTION = 'IPHONE_DISTRIBUTION';

export const MESSAGE_IDS_WITH_SEEKBAR = 'MESSAGE_IDS_WITH_SEEKBAR';

export const FLIX_SPLASH_DASH = 'FLIX_SPLASH_DASH';
export const FLIX_SPLASH_HLS = 'FLIX_SPLASH_HLS';

export const RE_USER_INPUT = 'RE_USER_INPUT';
export const RE_USERNAME = 'RE_USERNAME';
export const MASQUED_PHRASES = 'MASQUED_PHRASES';
export const MASQUED_PHRASES_FOR_CHAT = 'MASQUED_PHRASES_FOR_CHAT';

export const HAPPY_HOUR_FEED = 'HAPPY_HOUR_FEED';
export const SHOWCASE_FEED = 'SHOWCASE_FEED';
export const FREE_FLIX_FEED = 'FREE_FLIX_FEED';
export const POST_FEED = 'POST_FEED';
export const SUGGESTED_POST_FEED = 'SUGGESTED_POST_FEED';
export const POST_FEED_ASPECT_RATIO = 'POST_FEED_ASPECT_RATIO';
export const POST_COVER_IMAGE_SIZE = 'POST_COVER_IMAGE_SIZE';

export const FLIX_DETAIL_FEED = 'FLIX_DETAIL_FEED';
export const SEARCH_FLIX_FEED = 'SEARCH_FLIX_FEED';
export const SEARCH_STORY_FEED = 'SEARCH_STORY_FEED';
export const SEARCH_SWAGGER_ONLINE_FEED = 'SEARCH_SWAGGER_ONLINE_FEED';
export const SEARCH_SWAGGER_NEW_FEED = 'SEARCH_SWAGGER_NEW_FEED';
export const SEARCH_MESSAGE_SORTINGS = 'SEARCH_MESSAGE_SORTINGS';
export const SEARCH_USER_SORTINGS = 'SEARCH_USER_SORTINGS';

export const LIVESTREAM_FEED = 'LIVESTREAM_FEED';
export const LIVESTREAM_FEED_SORTINGS = 'LIVESTREAM_FEED_SORTINGS';
export const LIVESTREAM_SHOWCASE_FEED = 'LIVESTREAM_SHOWCASE_FEED';
export const LIVESTREAM_MAX_PIN_LENGTH = 'LIVESTREAM_MAX_PIN_LENGTH';
export const LIVESTREAM_CARD_PRIORITIZED_BADGES =
  'LIVESTREAM_CARD_PRIORITIZED_BADGES';
export const LIVESTREAM_AVATAR_QUEUE_DELAY_MSEC =
  'LIVESTREAM_AVATAR_QUEUE_DELAY_MSEC';
export const LIVESTREAM_CHAT_LIST_LIMITATION =
  'LIVESTREAM_CHAT_LIST_LIMITATION';
export const LIVESTREAM_PAY_DURATION_SECONDS =
  'LIVESTREAM_PAY_DURATION_SECONDS';
export const PRIVATE_LIVESTREAM_INSUFFICIENT_BALANCE_REMAINING_PRICE_MULTIPLIER =
  'PRIVATE_LIVESTREAM_INSUFFICIENT_BALANCE_REMAINING_PRICE_MULTIPLIER';
export const BYTEPLUS_APP_ID = 'BYTEPLUS_APP_ID';
export const BYTEPLUS_RTM_URL_TEMPLATE = 'BYTEPLUS_RTM_URL_TEMPLATE';
export const BYTEPLUS_RTM_FLV_URL_TEMPLATE = 'BYTEPLUS_RTM_FLV_URL_TEMPLATE';
export const BYTEPLUS_RTM_FLV_URL_IOS_17_1_ABOVE_TEMPLATE =
  'BYTEPLUS_RTM_FLV_URL_IOS_17_1_ABOVE_TEMPLATE';
export const BYTEPLUS_RTM_HLS_URL_TEMPLATE = 'BYTEPLUS_RTM_HLS_URL_TEMPLATE';
export const BYTEPLUS_RTM_SDP_FALLBACK_ERROR_CODES =
  'BYTEPLUS_RTM_SDP_FALLBACK_ERROR_CODES';
export const BYTEPLUS_RTC_ROOM_PROFILE_TYPE = 'BYTEPLUS_RTC_ROOM_PROFILE_TYPE';
export const BYTEPLUS_RTC_REMOTE_VIDEO_CONFIG_WIDTH =
  'BYTEPLUS_RTC_REMOTE_VIDEO_CONFIG_WIDTH';
export const BYTEPLUS_RTC_REMOTE_VIDEO_CONFIG_HEIGHT =
  'BYTEPLUS_RTC_REMOTE_VIDEO_CONFIG_HEIGHT';
export const BYTEPLUS_RTM_FLV_CODEC = 'BYTEPLUS_RTM_FLV_CODEC';
export const BYTEPLUS_RTM_SHOULD_PATCH_FETCH =
  'BYTEPLUS_RTM_SHOULD_PATCH_FETCH';
export const BYTEPLUS_RTM_RTMP_STREAM_IDS = 'BYTEPLUS_RTM_RTMP_STREAM_IDS';
export const BYTEPLUS_RTM_RTMP_FLV_URL_TEMPLATE =
  'BYTEPLUS_RTM_RTMP_FLV_URL_TEMPLATE';
export const BYTEPLUS_RTM_RTMP_URL_TEMPLATE = 'BYTEPLUS_RTM_RTMP_URL_TEMPLATE';

export const STREAM_EXPENSIVE_GIFT_PRICE = 'STREAM_EXPENSIVE_GIFT_PRICE';
export const GIFT_CATEGORIES_LIVESTREAM = 'GIFT_CATEGORIES_LIVESTREAM';
export const LIVESTREAM_ANIMATION_GIFTS = 'LIVESTREAM_ANIMATION_GIFTS';
export const FEATURE_LIVESTREAM_DISPLAY_USERNAMES =
  'FEATURE_LIVESTREAM_DISPLAY_USERNAMES';
export const FEATURE_LIVESTREAM_AD = 'FEATURE_LIVESTREAM_AD';
export const FEATURE_LIVESTREAM_AD_2 = 'FEATURE_LIVESTREAM_AD_2';
export const BLUROUT_PARAMETERS = 'BLUROUT_PARAMETERS';
export const LIVESTREAM_VIEWERS_MAX = 'LIVESTREAM_VIEWERS_MAX';
export const LIVESTREAM_VIEWERS_MIN = 'LIVESTREAM_VIEWERS_MIN';
export const FEATURE_LIVESTREAM_INSTANT_COMMAND_TOOLTIP =
  'FEATURE_LIVESTREAM_INSTANT_COMMAND_TOOLTIP';
export const FEATURE_LIVESTREAM_OVERLAY_CTA = 'FEATURE_LIVESTREAM_OVERLAY_CTA';
export const LIVESTREAM_AVAILABILITY_CHECK_TIMEOUT_MSEC =
  'LIVESTREAM_AVAILABILITY_CHECK_TIMEOUT_MSEC';

export const LIVESTREAM_PUBLIC_SOURCE_FORMAT =
  'LIVESTREAM_PUBLIC_SOURCE_FORMAT';
export const LIVESTREAM_PRIVATE_SOURCE_FORMAT =
  'LIVESTREAM_PRIVATE_SOURCE_FORMAT';
export const LIVESTREAM_PLAYER_WAITING_THRESHOLD_MSEC =
  'LIVESTREAM_PLAYER_WAITING_THRESHOLD_MSEC';
export const LIVESTREAM_FLV_MIN_BUFFER = 'LIVESTREAM_FLV_MIN_BUFFER';
export const LIVESTREAM_FLV_MAX_BUFFER = 'LIVESTREAM_FLV_MAX_BUFFER';
export const LIVESTREAM_DRM_VIDEO_ROBUSTNESS_WIDEVINE =
  'FEATURE_LIVESTREAM_DRM_VIDEO_ROBUSTNESS_COM_WIDEVINE_ALPHA';
export const LIVESTREAM_DRM_VIDEO_ROBUSTNESS_PLAYREADY =
  'FEATURE_LIVESTREAM_DRM_VIDEO_ROBUSTNESS_COM_MICROSOFT_PLAYREADY';
export const ENABLED_LOW_LATENCY = 'ENABLED_LOW_LATENCY';
export const LOW_LATENCY_PROFILE_VALUE_DASH = 'LOW_LATENCY_PROFILE_VALUE_DASH';
export const LOW_LATENCY_PROFILE_VALUE_HLS = 'LOW_LATENCY_PROFILE_VALUE_HLS';
export const LOW_LATENCY_LIVE_DELAY_PUBLIC = 'LOW_LATENCY_LIVE_DELAY_PUBLIC';
export const LOW_LATENCY_LIVE_DELAY_PRIVATE = 'LOW_LATENCY_LIVE_DELAY_PRIVATE';
export const LOW_LATENCY_PLAYER_WAITING_HINT_COOL_DOWN_SECONDS =
  'LOW_LATENCY_PLAYER_WAITING_HINT_COOL_DOWN_SECONDS';
export const LOW_LATENCY_PLAYER_WAITING_WINDOW_SECONDS =
  'LOW_LATENCY_PLAYER_WAITING_WINDOW_SECONDS';
export const LOW_LATENCY_PLAYER_WAITING_MAX_SECONDS_PER_WINDOW =
  'LOW_LATENCY_PLAYER_WAITING_MAX_SECONDS_PER_WINDOW';
export const LOW_LATENCY_PLAYER_WAITING_FRAGMENT_INTERVAL_SECONDS =
  'LOW_LATENCY_PLAYER_WAITING_FRAGMENT_INTERVAL_SECONDS';
export const LIVESTREAM_LOW_DEFINITION_VIDEO_IDS =
  'LIVESTREAM_LOW_DEFINITION_VIDEO_IDS';

export const MUX_DATA_KEY = 'MUX_DATA_KEY';

export const LOGIN_METHODS_PRIMARY = 'LOGIN_METHODS_PRIMARY';
export const LOGIN_METHODS_SECONDARY = 'LOGIN_METHODS_SECONDARY';
export const SIGNUP_METHODS_PRIMARY = 'SIGNUP_METHODS_PRIMARY';
export const SIGNUP_METHODS_SECONDARY = 'SIGNUP_METHODS_SECONDARY';
export const SIGNUP_BECOME_CREATOR = 'SIGNUP_BECOME_CREATOR';

export const FREE_PEEK_LIVESTREAM_ROW_INDEX = 'FREE_PEEK_LIVESTREAM_ROW_INDEX';

export const REPORT_EMAIL = 'REPORT_EMAIL';
export const REPORT_EMAIL_MAILTO = 'REPORT_EMAIL_MAILTO';
export const REPORT_EMAIL_SUBJECT = 'REPORT_EMAIL_SUBJECT';
export const REPORT_EMAIL_SUBJECT_PLACEHOLDER =
  'REPORT_EMAIL_SUBJECT_PLACEHOLDER';
export const REPORT_EMAIL_BODY = 'REPORT_EMAIL_BODY';

export const MESSAGE_BADGE_NONE = 'MESSAGE_BADGE_NONE';

export const SECURIONPAY_PUBLIC_KEY = 'SECURIONPAY_PUBLIC_KEY';
export const TAPPAY_APPLE_PAY_ID = 'TAPPAY_APPLE_PAY_ID';
export const CHINA_PAYMENT_BUSINESS_HOUR = 'CHINA_PAYMENT_BUSINESS_HOUR';
export const SHOP_REWARD_BADGES = 'SHOP_REWARD_BADGES';
export const SHOP_CURRENCIES = 'SHOP_CURRENCIES';
export const ZERO_DECIMAL_CURRENCIES = 'ZERO_DECIMAL_CURRENCIES';
export const ECPAY_APPLE_PAY_MERCHANT_ID = 'ECPAY_APPLE_PAY_MERCHANT_ID';

export const SHOW_FUNDING_OPTIONS_MIDDLE = 'SHOW_FUNDING_OPTIONS_MIDDLE';
export const SHOW_FUNDING_END_COUNTDOWN = 'SHOW_FUNDING_END_COUNTDOWN';
export const SHOW_HESITATE_COUNTDOWN = 'SHOW_HESITATE_COUNTDOWN';

export const CHINA_PAYMENT_ALIPAY_URL = 'CHINA_PAYMENT_ALIPAY_URL';
export const CHINA_PAYMENT_ALIPAY_WECHAT_URL =
  'CHINA_PAYMENT_ALIPAY_WECHAT_URL';

export const LOKALISE_LANGUAGES_KEY = 'LOKALISE_LANGUAGES_KEY';
export const LOKALISE_LANGUAGES_NAME = 'LOKALISE_LANGUAGES_NAME';

export const LEADERBOARD_CATEGORIES = 'LEADERBOARD_CATEGORIES';

export const DASH_DRM_RULESET_SAMPLE = 'DASH_DRM_RULESET_SAMPLE';
export const WIDEVINE_RULESETS = 'WIDEVINE_RULESETS';
export const PLAYREADY_RULESETS = 'PLAYREADY_RULESETS';
export const FAIRPLAY_RULESETS = 'FAIRPLAY_RULESETS';

export const NEED_LANDING_COUNTRIES = 'NEED_LANDING_COUNTRIES';
export const NEED_OVER_18_COUNTRIES = 'NEED_OVER_18_COUNTRIES';
export const PREVIEW_PRESET = 'PREVIEW_PRESET';
export const FLIX_TRAILER_DURATION = 'FLIX_TRAILER_DURATION';
export const TRAILER_BLOCKER_URL = 'TRAILER_BLOCKER_URL';
export const LANDING_BLOCKER_URL = 'LANDING_BLOCKER_URL';
export const UNDER_AGE_EXIT_URL = 'UNDER_AGE_EXIT_URL';

// these remote keys are for swagr
export const FLIX_TITLE_CHARS_MAX = 'FLIX_TITLE_CHARS_MAX';
export const FLIX_CAPTION_CHARS_MAX = 'FLIX_CAPTION_CHARS_MAX';

export const ASSET_FILE_SIZE_MAX = 'ASSET_FILE_SIZE_MAX';
export const ARTIFACT_THUMBNAIL_FILE_SIZE_MAX =
  'ARTIFACT_THUMBNAIL_FILE_SIZE_MAX';
export const ARTIFACT_TRAILER_FILE_SIZE_MAX = 'ARTIFACT_TRAILER_FILE_SIZE_MAX';
export const UPLOADER_VIDEO_LENGTH_SEC_MIN_MAX =
  'UPLOADER_VIDEO_LENGTH_SEC_MIN_MAX';
export const MESSAGE_VIDEO_LENGTH_SEC_MIN_MAX =
  'MESSAGE_VIDEO_LENGTH_SEC_MIN_MAX';

export const POST_DIAMOND_MIN = 'POST_DIAMOND_MIN';
export const POST_DIAMOND_MAX = 'POST_DIAMOND_MAX';
export const POST_DIAMOND_INTERVAL = 'POST_DIAMOND_INTERVAL';
export const POST_DIAMOND_DEFAULT = 'POST_DIAMOND_DEFAULT';
export const POST_IMAGE_DIAMOND_MIN = 'POST_IMAGE_DIAMOND_MIN';
export const POST_IMAGE_DIAMOND_MAX = 'POST_IMAGE_DIAMOND_MAX';
export const POST_IMAGE_DIAMOND_INTERVAL = 'POST_IMAGE_DIAMOND_INTERVAL';
export const POST_IMAGE_DIAMOND_DEFAULT = 'POST_IMAGE_DIAMOND_DEFAULT';
export const POST_ASSET_MAX_QUANTITIES = 'POST_ASSET_MAX_QUANTITIES';
export const BROADCAST_MESSAGE_VIDEO_DIAMOND_INTERVAL =
  'BROADCAST_MESSAGE_VIDEO_DIAMOND_INTERVAL';
export const BROADCAST_MESSAGE_VIDEO_DIAMOND_MAX =
  'BROADCAST_MESSAGE_VIDEO_DIAMOND_MAX';
export const BROADCAST_MESSAGE_VIDEO_DIAMOND_MIN =
  'BROADCAST_MESSAGE_VIDEO_DIAMOND_MIN';
export const CHAT_VIDEO_LENGTH_MAX = 'CHAT_VIDEO_LENGTH_MAX';
export const USERNAME_PLACEHOLDER = 'USERNAME_PLACEHOLDER';
export const SCHEDULE_POST_DEFAULT_DURATION_SEC =
  'SCHEDULE_POST_DEFAULT_DURATION_SEC';
export const STORY_TTL_SECONDS = 'STORY_TTL_SECONDS';
export const FEATURE_EDIT_POST_PRICE = 'FEATURE_EDIT_POST_PRICE';

export const FEATURE_ACCOUNT_REFERRAL_CODE = 'FEATURE_ACCOUNT_REFERRAL_CODE';
export const USER_TO_USER_REFERRAL_BANNER = 'USER_TO_USER_REFERRAL_BANNER';
export const FEATURE_ACCOUNT_REFERRAL_CODE_CREATOR =
  'FEATURE_ACCOUNT_REFERRAL_CODE_CREATOR';

export const AUTO_TOP_UP_PRODUCT_ID = 'AUTO_TOP_UP_PRODUCT_ID';
export const AUTO_TOP_UP_TRIGGER_THRESHOLD = 'AUTO_TOP_UP_TRIGGER_THRESHOLD';

export const DRM_LICENSE_DURATION = 'DRM_LICENSE_DURATION';

export const LEADERBOARD_IDS = 'LEADERBOARD_IDS';
export const VIP_LEVEL_INFORM_EXPIRE_SOON_DAYS =
  'VIP_LEVEL_INFORM_EXPIRE_SOON_DAYS';
export const VIP_LEVEL_LIVESTREAM_NOTIFY_THRESHOLD =
  'VIP_LEVEL_LIVESTREAM_NOTIFY_THRESHOLD';
export const VIP_LEVEL_DIAMONDS = 'VIP_LEVEL_DIAMONDS';
export const USER_LEVEL_ALL_BENEFITS = 'USER_LEVEL_ALL_BENEFITS';
export const VIP_LEVEL_1_LIVESTREAM_STYLE = 'VIP_LEVEL_1_LIVESTREAM_STYLE';
export const VIP_LEVEL_2_LIVESTREAM_STYLE = 'VIP_LEVEL_2_LIVESTREAM_STYLE';
export const VIP_LEVEL_3_LIVESTREAM_STYLE = 'VIP_LEVEL_3_LIVESTREAM_STYLE';
export const VIP_LEVEL_4_LIVESTREAM_STYLE = 'VIP_LEVEL_4_LIVESTREAM_STYLE';
export const VIP_LEVEL_5_LIVESTREAM_STYLE = 'VIP_LEVEL_5_LIVESTREAM_STYLE';
export const VIP_LEVEL_6_LIVESTREAM_STYLE = 'VIP_LEVEL_6_LIVESTREAM_STYLE';
export const VIP_LEVEL_7_LIVESTREAM_STYLE = 'VIP_LEVEL_7_LIVESTREAM_STYLE';
export const VIP_LEVEL_8_LIVESTREAM_STYLE = 'VIP_LEVEL_8_LIVESTREAM_STYLE';

// Journal v2 field type
export const JOURNAL_V2_FIELD_DATETIME = 'JOURNAL_V2_FIELD_DATETIME';
export const JOURNAL_V2_FIELD_PRICE = 'JOURNAL_V2_FIELD_PRICE';
export const JOURNAL_V2_FIELD_GIFT = 'JOURNAL_V2_FIELD_GIFT';
export const JOURNAL_V2_FIELD_PRODUCT = 'JOURNAL_V2_FIELD_PRODUCT';
export const JOURNAL_V2_FIELD_OEMBED = 'JOURNAL_V2_FIELD_OEMBED';
export const JOURNAL_V2_FIELD_DIAMOND = 'JOURNAL_V2_FIELD_DIAMOND';

export const SUPPORTED_LANGUAGES = 'SUPPORTED_LANGUAGES';

export const SWAG_BLOG = 'SWAG_BLOG';
export const SWAG_CAREERS = 'SWAG_CAREERS';
export const SWAG_SELECT = 'SWAG_SELECT';
export const SWAG_BECOME_CREATOR = 'SWAG_BECOME_CREATOR';
export const SWAG_HELP_CENTER = 'SWAG_HELP_CENTER';
export const SWAG_DISPLAY_NAME_GUIDELINES = 'SWAG_DISPLAY_NAME_GUIDELINES';
export const HELP_ARTICLE_ID_VIEWER_USER_TRIGGER =
  'HELP_ARTICLE_ID_VIEWER_USER_TRIGGER';
export const HELP_ARTICLE_LINK_DEVICE_INSTRUCTIONS =
  'HELP_ARTICLE_LINK_DEVICE_INSTRUCTIONS';
export const HELP_ARTICLE_LINK_LIVESTREAM_USER_TRIGGER =
  'HELP_ARTICLE_LINK_LIVESTREAM_USER_TRIGGER';

export const SWAG_WEBAUTHN_GUIDELINES = 'SWAG_WEBAUTHN_GUIDELINES';

export const APP_DOWNLOAD_LINK_CREATOR = 'APP_DOWNLOAD_LINK_CREATOR';
export const APP_DOWNLOAD_LINK_NORMAL = 'APP_DOWNLOAD_LINK_NORMAL';

export const HASHTAG_REGEX_PATTERN = 'HASHTAG_REGEX_PATTERN';

export const SMS_SUPPORTED_COUNTRIES = 'SMS_SUPPORTED_COUNTRIES';

export const KYC_SUPPORTED_COUNTRIES = 'KYC_SUPPORTED_COUNTRIES';
export const KYC_FRONT_ONLY = 'KYC_FRONT_ONLY';
export const KYC_USER_FRONT_ONLY = 'KYC_USER_FRONT_ONLY';
export const KYC_PAYMENT_GATEWAY = 'KYC_PAYMENT_GATEWAY';

export const HELP_ARTICLE_ID_HASHTAG = 'HELP_ARTICLE_ID_HASHTAG';
export const HELP_ARTICLE_ID_SIC_BO = 'HELP_ARTICLE_ID_SIC_BO';
export const HELP_ARTICLE_ID_SHORT_CLIP = 'HELP_ARTICLE_ID_SHORT_CLIP';
export const HELP_ARTICLE_ID_MESSAGE_PACK = 'HELP_ARTICLE_ID_MESSAGE_PACK';
export const HELP_ARTICLE_LINK_MESSAGE_PACK = 'HELP_ARTICLE_LINK_MESSAGE_PACK';
export const HELP_ARTICLE_ID_GIFT_SLOT = 'HELP_ARTICLE_ID_GIFT_SLOT';

export const HELP_ARTICLE_LINK_POST = 'HELP_ARTICLE_LINK_POST';
export const HELP_ARTICLE_LINK_GIFT_SLOT = 'HELP_ARTICLE_LINK_GIFT_SLOT';
export const HELP_ARTICLE_LINK_SIC_BO = 'HELP_ARTICLE_LINK_SIC_BO';
export const HELP_ARTICLE_LINK_CREATOR_REFERRAL =
  'HELP_ARTICLE_LINK_CREATOR_REFERRAL';
export const HELP_ARTICLE_LINK_DISPLAY_NAME = 'HELP_ARTICLE_LINK_DISPLAY_NAME';
export const HELP_ARTICLE_LINK_WEBAUTHN = 'HELP_ARTICLE_LINK_WEBAUTHN';

export const CREDIT_CARD_TYPES_TAPPAY = 'CREDIT_CARD_TYPES_TAPPAY';
export const CREDIT_CARD_TYPES_SECURIONPAY = 'CREDIT_CARD_TYPES_SECURIONPAY';

export const FLIX_DISPLAY_UNLOCK_COUNT = 'FLIX_DISPLAY_UNLOCK_COUNT';
export const HOME_HEADER_DISPLAY_FREE_BADGE = 'HOME_HEADER_DISPLAY_FREE_BADGE';
export const HOME_HEADER_DISPLAY_NEW_BADGE = 'HOME_HEADER_DISPLAY_NEW_BADGE';
export const TRENDING_HASHTAG_FEED = 'TRENDING_HASHTAG_FEED';
export const TRENDING_CATEGORY_FEED = 'TRENDING_CATEGORY_FEED';
export const HASHTAG_CATEGORIES = 'HASHTAG_CATEGORIES';

export const APP_BUNDLE_IDS = 'APP_BUNDLE_IDS';
export const PUSHER_APP_ID = 'PUSHER_APP_ID';
export const PUSHER_APP_KEY = 'PUSHER_APP_KEY';
export const PUSHER_APP_CLUSTER = 'PUSHER_APP_CLUSTER';
export const PUSHY_APP_ID = 'PUSHY_APP_ID';
export const PUSHER_WSS_HOST = 'PUSHER_WSS_HOST';
export const PUSHER_WS_HOST = 'PUSHER_WS_HOST';
export const PUSHER_WSS_PORT = 'PUSHER_WSS_PORT';
export const PUSHER_ENABLED_TRANSPORTS = 'PUSHER_ENABLED_TRANSPORTS';

export const DIAMOND_SHOP_METHOD_TYPES_BLACKLIST =
  'DIAMOND_SHOP_METHOD_TYPES_BLACKLIST';
export const DIAMOND_SHOP_METHOD_TYPES_BLACKLIST_TIMEOUT_SECS =
  'DIAMOND_SHOP_METHOD_TYPES_BLACKLIST_TIMEOUT_SECS';

export const MAX_BATCH_PLAYER_DATA_LENGTH = 'MAX_BATCH_PLAYER_DATA_LENGTH';

export const REGISTER_OPTIONAL_BIND_EMAIL_MODAL =
  'REGISTER_OPTIONAL_BIND_EMAIL_MODAL';
export const REGISTER_OPTIONAL_AGE_VERIFY = 'REGISTER_OPTIONAL_AGE_VERIFY';
export const REGISTER_OPTIONAL_AGE_VERIFY_NOTIFICATION =
  'REGISTER_OPTIONAL_AGE_VERIFY_NOTIFICATION';
export const REGISTER_OPTIONAL_BIND_PHONE_NUMBER_MODAL =
  'REGISTER_OPTIONAL_BIND_PHONE_NUMBER_MODAL';

export const FEATURE_DRM_CAPABILITY_AUTO_CHECK =
  'FEATURE_DRM_CAPABILITY_AUTO_CHECK';
export const DRM_CAPABILITY_CHECK_MESSAGE_IDS =
  'DRM_CAPABILITY_CHECK_MESSAGE_IDS';
export const DRM_REQUIREMENT_FEATURE_FLIX = 'DRM_REQUIREMENT_FEATURE_FLIX';
export const DRM_REQUIREMENT_FEATURE_STORY = 'DRM_REQUIREMENT_FEATURE_STORY';
export const DRM_REQUIREMENT_FEATURE_CHAT = 'DRM_REQUIREMENT_FEATURE_CHAT';
export const DRM_REQUIREMENT_FEATURE_LIVESTREAM =
  'DRM_REQUIREMENT_FEATURE_LIVESTREAM';
export const DRM_REQUIREMENT_FEATURE_SHORT = 'DRM_REQUIREMENT_FEATURE_SHORT';
export const DRM_REQUIREMENT_FEATURE_POST = 'DRM_REQUIREMENT_FEATURE_POST';
export const WIDEVINE_SERVER_CERTIFICATE_BASE64 =
  'WIDEVINE_SERVER_CERTIFICATE_BASE64';

export const GIFT_CATEGORIES_CHAT = 'GIFT_CATEGORIES_CHAT';

export const FINGERPRINTJS_API_KEY = 'FINGERPRINTJS_API_KEY';
export const FINGERPRINTJS_ENDPOINT = 'FINGERPRINTJS_ENDPOINT';
export const FINGERPRINTJS_REGION = 'FINGERPRINTJS_REGION';

export const PING_CHECK_URLS = 'PING_CHECK_URLS';

export const POST_COMMENT_MAX_LENGTH = 'POST_COMMENT_MAX_LENGTH';
export const POST_DETAIL_THUMBNAIL_SIZE = 'POST_DETAIL_THUMBNAIL_SIZE';

export const CP_REPLY_DIAMOND_MIN_MAX_INTERVAL =
  'CP_REPLY_DIAMOND_MIN_MAX_INTERVAL';

export const SUBSCRIPTION_PLAN_AMOUNT_MIN = 'SUBSCRIPTION_PLAN_AMOUNT_MIN';
export const SUBSCRIPTION_PLAN_AMOUNT_MAX = 'SUBSCRIPTION_PLAN_AMOUNT_MAX';
export const SUBSCRIPTION_PLAN_CURRENCY = 'SUBSCRIPTION_PLAN_CURRENCY';
export const SUBSCRIPTION_CURRENCY = 'SUBSCRIPTION_CURRENCY';
export const SUBSCRIPTION_PAYMENT_GATEWAY = 'SUBSCRIPTION_PAYMENT_GATEWAY';

export const BIOGRAPHY_MAX_LENGTH = 'BIOGRAPHY_MAX_LENGTH';

export const DISPLAY_NAME_MAX_LENGTH = 'DISPLAY_NAME_MAX_LENGTH';
export const FEATURE_DISPLAY_NAME = 'FEATURE_DISPLAY_NAME';

export const PAYOUT_PAYMENT_SUPPORTED_METHODS =
  'PAYOUT_PAYMENT_SUPPORTED_METHODS';
export const BACKPACK_EXPIRING_THRESHOLD_SECS =
  'BACKPACK_EXPIRING_THRESHOLD_SECS';

export const PAYMENT_SUPPORTED_METHODS_USD = 'PAYMENT_SUPPORTED_METHODS_USD';
export const PAYMENT_SUPPORTED_METHODS_TWD = 'PAYMENT_SUPPORTED_METHODS_TWD';
export const PAYMENT_SUPPORTED_METHODS_CNY = 'PAYMENT_SUPPORTED_METHODS_CNY';
export const PAYMENT_SUPPORTED_METHODS_AUD = 'PAYMENT_SUPPORTED_METHODS_AUD';
export const PAYMENT_SUPPORTED_METHODS_JPY = 'PAYMENT_SUPPORTED_METHODS_JPY';
export const PAYMENT_SUPPORTED_METHODS_CAD = 'PAYMENT_SUPPORTED_METHODS_CAD';
export const PAYMENT_SUPPORTED_METHODS_EUR = 'PAYMENT_SUPPORTED_METHODS_EUR';
export const PAYMENT_SUPPORTED_METHODS_GBP = 'PAYMENT_SUPPORTED_METHODS_GBP';
export const PAYMENT_SUPPORTED_METHODS_HKD = 'PAYMENT_SUPPORTED_METHODS_HKD';
export const PAYMENT_SUPPORTED_METHODS_KRW = 'PAYMENT_SUPPORTED_METHODS_KRW';
export const PAYMENT_SUPPORTED_METHODS_MYR = 'PAYMENT_SUPPORTED_METHODS_MYR';
export const PAYMENT_SUPPORTED_METHODS_NZD = 'PAYMENT_SUPPORTED_METHODS_NZD';
export const PAYMENT_SUPPORTED_METHODS_PHP = 'PAYMENT_SUPPORTED_METHODS_PHP';
export const PAYMENT_SUPPORTED_METHODS_SGD = 'PAYMENT_SUPPORTED_METHODS_SGD';
export const PAYMENT_SUPPORTED_METHODS_THB = 'PAYMENT_SUPPORTED_METHODS_THB';
export const PAYMENT_SUPPORTED_METHODS_VND = 'PAYMENT_SUPPORTED_METHODS_VND';

export const NEED_AUTH_HANDLER = 'NEED_AUTH_HANDLER';
export const FEATURE_CREATOR_ENTRY = 'FEATURE_CREATOR_ENTRY';

export const SHORT_VIDEO_DURATION_SECONDS = 'SHORT_VIDEO_DURATION_SECONDS';
export const CLOUDFLARE_TURNSTILE_SITE_KEY = 'CLOUDFLARE_TURNSTILE_SITE_KEY';
export const ANALYTICS_GOOGLECLOUD_ACCESS_TOKEN =
  'ANALYTICS_GOOGLECLOUD_ACCESS_TOKEN';
export const ANALYTICS_TRACKING_PUBSUB_TOPIC =
  'ANALYTICS_TRACKING_PUBSUB_TOPIC';

export const OUTBOX_FEED_STORY = 'OUTBOX_FEED_STORY';
export const OUTBOX_FEED_STORY_SORTINGS = 'OUTBOX_FEED_STORY_SORTINGS';
export const OUTBOX_FEED_POST_VIDEO = 'OUTBOX_FEED_POST_VIDEO';
export const OUTBOX_FEED_POST_VIDEO_SORTINGS =
  'OUTBOX_FEED_POST_VIDEO_SORTINGS';
export const OUTBOX_FEED_POST_IMAGE = 'OUTBOX_FEED_POST_IMAGE';
export const OUTBOX_FEED_POST_IMAGE_SORTINGS =
  'OUTBOX_FEED_POST_IMAGE_SORTINGS';
export const OUTBOX_FEED_SHORT = 'OUTBOX_FEED_SHORT';
export const OUTBOX_FEED_SHORT_SORTINGS = 'OUTBOX_FEED_SHORT_SORTINGS';
export const OUTBOX_FEED_POST = 'OUTBOX_FEED_POST';

export const OUTBOX_FEED_ALL_DRAFT = 'OUTBOX_FEED_ALL_DRAFT';
export const OUTBOX_FEED_ALL_DRAFT_SORTINGS = 'OUTBOX_FEED_ALL_DRAFT_SORTINGS';
export const OUTBOX_FEED_ALL_DRAFT_FILTERS = 'OUTBOX_FEED_ALL_DRAFT_FILTERS';

export const OUTBOX_FEED_DELAY_TIME_IN_SECONDS =
  'OUTBOX_FEED_DELAY_TIME_IN_SECONDS';

export const HASHTAG_FEED_STORY = 'HASHTAG_FEED_STORY';
export const HASHTAG_FEED_POST_VIDEO = 'HASHTAG_FEED_POST_VIDEO';
export const HASHTAG_FEED_STORY_SORTINGS = 'HASHTAG_FEED_STORY_SORTINGS';
export const HASHTAG_FEED_POST_VIDEO_SORTINGS =
  'HASHTAG_FEED_POST_VIDEO_SORTINGS';

export const HASHTAG_FEED_USER = 'HASHTAG_FEED_USER';

export const JWT_SCOPE_CURATOR = 'JWT_SCOPE_CURATOR';
export const JWT_SCOPE_CREATOR = 'JWT_SCOPE_CREATOR';
export const JWT_SCOPE_SUBSCRIBABLE = 'JWT_SCOPE_SUBSCRIBABLE';
export const JWT_SCOPE_STATS_PERSONAL = 'JWT_SCOPE_STATS_PERSONAL';
export const JWT_SCOPE_UPLOADER_POST = 'JWT_SCOPE_UPLOADER_POST';
export const JWT_SCOPE_UPLOADER_BROADCAST = 'JWT_SCOPE_UPLOADER_BROADCAST';
export const JWT_SCOPE_UPLOADER_CHAT = 'JWT_SCOPE_UPLOADER_CHAT';
export const JWT_SCOPE_UPLOADER_REPLY = 'JWT_SCOPE_UPLOADER_REPLY';
export const JWT_SCOPE_MESSAGE_CLIP = 'JWT_SCOPE_MESSAGE_CLIP';
export const JWT_SCOPE_LIVESTREAM = 'JWT_SCOPE_LIVESTREAM';
export const JWT_SCOPE_LIVESTREAM_CHAT = 'JWT_SCOPE_LIVESTREAM_CHAT';
export const JWT_SCOPE_MESSAGE_HIDE = 'JWT_SCOPE_MESSAGE_HIDE';
export const JWT_SCOPE_MESSAGE_EDIT = 'JWT_SCOPE_MESSAGE_EDIT';
export const JWT_SCOPE_LIVESTREAM_ALERT = 'JWT_SCOPE_LIVESTREAM_ALERT';
export const JWT_SCOPE_LIVESTREAM_MODERATOR = 'JWT_SCOPE_LIVESTREAM_MODERATOR';

export const TOKEN_EXPIRED_THRESHOLD = 'TOKEN_EXPIRED_THRESHOLD';

export const ONLOAD_REDIRECT_URL = 'ONLOAD_REDIRECT_URL';

export const AUTHENTICATE_BANNER = 'AUTHENTICATE_BANNER';
export const AUTHENTICATE_BANNER_SIGNUP = 'AUTHENTICATE_BANNER_SIGNUP';
export const AUTHENTICATE_BANNER_USER_KYC = 'AUTHENTICATE_BANNER_USER_KYC';
export const AUTHENTICATE_BANNER_BIND_EMAIL = 'AUTHENTICATE_BANNER_BIND_EMAIL';

export const DASHJS_ABR_STRATEGY = 'DASHJS_ABR_STRATEGY';
export const DASHJS_INSUFFICIENT_BUFFER_RULE =
  'DASHJS_INSUFFICIENT_BUFFER_RULE';
export const DASHJS_SWITCH_HISTORY_RULE = 'DASHJS_SWITCH_HISTORY_RULE';
export const DASHJS_DROPPED_FRAMES_RULE = 'DASHJS_DROPPED_FRAMES_RULE';
export const DASHJS_ABANDON_REQUESTS_RULE = 'DASHJS_ABANDON_REQUESTS_RULE';
export const DASHJS_CATCH_UP_ENABLED = 'DASHJS_CATCH_UP_ENABLED';
export const DASHJS_CATCH_UP_MAX_DRIFT = 'DASHJS_CATCH_UP_MAX_DRIFT';
export const DASHJS_CATCH_UP_PLAYBACK_RATE_MAX =
  'DASHJS_CATCH_UP_PLAYBACK_RATE_MAX';
export const DASHJS_CATCH_UP_PLAYBACK_RATE_MIN =
  'DASHJS_CATCH_UP_PLAYBACK_RATE_MIN';
export const DASHJS_CATCH_UP_MECHANISM = 'DASHJS_CATCH_UP_MECHANISM';
export const DASHJS_ABR_FETCH_THROUGHPUT_CALCULATION_MODE =
  'DASHJS_ABR_FETCH_THROUGHPUT_CALCULATION_MODE';
export const DASHJS_BUFFER_STALLED_THRESHOLD =
  'DASHJS_BUFFER_STALLED_THRESHOLD';

export const DASHJS_LOW_LATENCY_ABR_STRATEGY =
  'DASHJS_LOW_LATENCY_ABR_STRATEGY';
export const DASHJS_LOW_LATENCY_INSUFFICIENT_BUFFER_RULE =
  'DASHJS_LOW_LATENCY_INSUFFICIENT_BUFFER_RULE';
export const DASHJS_LOW_LATENCY_SWITCH_HISTORY_RULE =
  'DASHJS_LOW_LATENCY_SWITCH_HISTORY_RULE';
export const DASHJS_LOW_LATENCY_DROPPED_FRAMES_RULE =
  'DASHJS_LOW_LATENCY_DROPPED_FRAMES_RULE';
export const DASHJS_LOW_LATENCY_ABANDON_REQUESTS_RULE =
  'DASHJS_LOW_LATENCY_ABANDON_REQUESTS_RULE';
export const DASHJS_LOW_LATENCY_CATCH_UP_ENABLED =
  'DASHJS_LOW_LATENCY_CATCH_UP_ENABLED';
export const DASHJS_LOW_LATENCY_CATCH_UP_MAX_DRIFT =
  'DASHJS_LOW_LATENCY_CATCH_UP_MAX_DRIFT';
export const DASHJS_LOW_LATENCY_CATCH_UP_PLAYBACK_RATE_MAX =
  'DASHJS_LOW_LATENCY_CATCH_UP_PLAYBACK_RATE_MAX';
export const DASHJS_LOW_LATENCY_CATCH_UP_PLAYBACK_RATE_MIN =
  'DASHJS_LOW_LATENCY_CATCH_UP_PLAYBACK_RATE_MIN';
export const DASHJS_LOW_LATENCY_CATCH_UP_MECHANISM =
  'DASHJS_LOW_LATENCY_CATCH_UP_MECHANISM';
export const DASHJS_LOW_LATENCY_ABR_FETCH_THROUGHPUT_CALCULATION_MODE =
  'DASHJS_LOW_LATENCY_ABR_FETCH_THROUGHPUT_CALCULATION_MODE';
export const DASHJS_LOW_LATENCY_BUFFER_STALLED_THRESHOLD =
  'DASHJS_LOW_LATENCY_BUFFER_STALLED_THRESHOLD';
export const DASHJS_CMCD_ENABLED = 'DASHJS_CMCD_ENABLED';
export const DASHJS_CMSD_ENABLED = 'DASHJS_CMSD_ENABLED';
export const DASHJS_AV_RESYNC_INTERVAL_SEC = 'DASHJS_AV_RESYNC_INTERVAL_SEC';

export const SHORTS_FEED_ALIAS = 'SHORTS_FEED_ALIAS';
export const LIVESTREAM_CLIP_FEED_ALIAS = 'LIVESTREAM_CLIP_FEED_ALIAS';
export const INSERT_CLIP_AFTER_X_SHORTS = 'INSERT_CLIP_AFTER_X_SHORTS';
export const INSERT_CLIP_EVERY_X_SHORTS = 'INSERT_CLIP_EVERY_X_SHORTS';
export const INSERT_AD_AFTER_X_SHORTS = 'INSERT_AD_AFTER_X_SHORTS';
export const INSERT_AD_EVERY_X_SHORTS = 'INSERT_AD_EVERY_X_SHORTS';

export const FLIX_PRICE_VALUE = 'FLIX_PRICE_VALUE';
export const AUTO_MESSAGE_PRICE_VALUE = 'AUTO_MESSAGE_PRICE_VALUE';
export const LIVESTREAM_PRIVATE_PRICE_VALUE = 'LIVESTREAM_PRIVATE_PRICE_VALUE';
export const LIVESTREAM_SHOW_TICKET_PRICE_VALUE =
  'LIVESTREAM_SHOW_TICKET_PRICE_VALUE';
export const LIVESTREAM_SHOW_EARLY_BIRD_TICKET_PRICE_VALUE =
  'LIVESTREAM_SHOW_EARLY_BIRD_TICKET_PRICE_VALUE';
export const SHORTS_CTA_DELAY_SECONDS = 'SHORTS_CTA_DELAY_SECONDS';
export const LIVESTREAM_SHORTS_CTA_DELAY_SECONDS =
  'LIVESTREAM_SHORTS_CTA_DELAY_SECONDS';

export const LOGIN_LANDING_BACKGROUND_OEMBED_URL =
  'LOGIN_LANDING_BACKGROUND_OEMBED_URL';

export const USER_REFERRAL_CODE_SHARE_LINK = 'USER_REFERRAL_CODE_SHARE_LINK';
export const REFERRAL_REFERRER_REWARD_VALUE = 'REFERRAL_REFERRER_REWARD_VALUE';
export const FEATURE_PHONE_VERIFY_REWARD_NOTIFICATION_COOLDOWN_MSEC =
  'FEATURE_PHONE_VERIFY_REWARD_NOTIFICATION_COOLDOWN_MSEC';
export const REFERRAL_REFERRAL_REWARD_VALUE = 'REFERRAL_REFERRAL_REWARD_VALUE';
export const OEMBED_REFERRAL_PENDING_REDEMPTION_TEMPLATE_ID =
  'OEMBED_REFERRAL_PENDING_REDEMPTION_TEMPLATE_ID';

export const FEATURE_USERNAME_SIGN_UP_EMAIL_INPUT =
  'FEATURE_USERNAME_SIGN_UP_EMAIL_INPUT';

export const CREATOR_REFERRAL_CODE_SHARE_LINK =
  'CREATOR_REFERRAL_CODE_SHARE_LINK';
export const HELP_ARTICLE_ID_CREATOR_REFERRAL =
  'HELP_ARTICLE_ID_CREATOR_REFERRAL';

export const SHORTS_NEED_AUTH_THRESHOLD = 'SHORTS_NEED_AUTH_THRESHOLD';

export const IFRAME_MAIN_DOMAIN_WHITELIST = 'IFRAME_MAIN_DOMAIN_WHITELIST';
export const IFRAME_THIRD_PARTY_DOMAIN_WHITELIST =
  'IFRAME_THIRD_PARTY_DOMAIN_WHITELIST';
export const IFRAME_ALLOWED_URLS = 'IFRAME_ALLOWED_URLS';
export const IFRAME_BYPASS_PROXY_WHITELIST_DOMAINS =
  'IFRAME_BYPASS_PROXY_WHITELIST_DOMAINS';

export const FEATURE_STORY_VOLUME_BUTTON = 'FEATURE_STORY_VOLUME_BUTTON';

export const DMCA_ID = 'DMCA_ID';

export const POST_VIDEO_LENGTH_MIN = 'POST_VIDEO_LENGTH_MIN';
export const POST_VIDEO_LENGTH_MAX = 'POST_VIDEO_LENGTH_MAX';

export const USER_PROFILE_TABS_GROUP = 'USER_PROFILE_TABS_GROUP';
export const USER_PROFILE_TABS = 'USER_PROFILE_TABS';
export const USER_PROFILE_TABS_DRAFT = 'USER_PROFILE_TABS_DRAFT';
export const USER_PROFILE_STORY_LAYOUT = 'USER_PROFILE_STORY_LAYOUT';
export const USER_PROFILE_HOME_CATEGORIES = 'USER_PROFILE_HOME_CATEGORIES';

export const FEATURE_GAMES = 'FEATURE_GAMES';
export const GAME_AQUISITION_AUTH_TOKEN_THRESHOLD_SECONDS =
  'GAME_AQUISITION_AUTH_TOKEN_THRESHOLD_SECONDS';
export const GAME_NEXT_SESSION_THRESHOLD_SECONDS =
  'GAME_NEXT_SESSION_THRESHOLD_SECONDS';
export const GAME_DRAGON_TIGER_FIGHT_LANGUAGES =
  'GAME_DRAGON_TIGER_FIGHT_LANGUAGES';
export const GAME_DRAGON_TIGER_FIGHT_LAUNCH_URL =
  'GAME_DRAGON_TIGER_FIGHT_LAUNCH_URL';

export const CREATOR_OUTBOX_FEED_STORY_PIN_MAX =
  'CREATOR_OUTBOX_FEED_STORY_PIN_MAX';
export const CREATOR_OUTBOX_FEED_POST_VIDEO_PIN_MAX =
  'CREATOR_OUTBOX_FEED_POST_VIDEO_PIN_MAX';
export const CREATOR_OUTBOX_FEED_POST_IMAGE_PIN_MAX =
  'CREATOR_OUTBOX_FEED_POST_IMAGE_PIN_MAX';
export const CREATOR_OUTBOX_FEED_SHORT_PIN_MAX =
  'CREATOR_OUTBOX_FEED_SHORT_PIN_MAX';

export const DEFAULT_OG_IMAGE_URL = 'DEFAULT_OG_IMAGE_URL';
export const DEFAULT_OG_TITLE = 'DEFAULT_OG_TITLE';
export const DEFAULT_OG_DESCRIPTION = 'DEFAULT_OG_DESCRIPTION';

export const SHORT_VIDEO_LENGTH_MIN = 'SHORT_VIDEO_LENGTH_MIN';
export const FEATURE_CLIP_POST = 'FEATURE_CLIP_POST';

export const MESSAGE_PACK_FEED_ALIAS = 'MESSAGE_PACK_FEED_ALIAS';
export const USER_HASHTAG = 'USER_HASHTAG';

export const USER_EVENT_ICON = 'USER_EVENT_ICON';
export const USER_EVENT_REPORT_URL = 'USER_EVENT_REPORT_URL';

export const BROWSE_FOLLOWING_CATEGORIES = 'BROWSE_FOLLOWING_CATEGORIES';
export const BROWSE_EXPLORE_CATEGORIES = 'BROWSE_EXPLORE_CATEGORIES';
export const BROWSE_TABS = 'BROWSE_TABS';
export const FEED_CATEGORIES = 'FEED_CATEGORIES';
export const LIVESTREAM_OPERATION_HINT = 'LIVESTREAM_OPERATION_HINT';
export const LIVESTREAM_EXIT_ALERT = 'LIVESTREAM_EXIT_ALERT';
export const SHORTS_PREFETCHED_MESSAGE_AMOUNT =
  'SHORTS_PREFETCHED_MESSAGE_AMOUNT';
export const FOLLOWING_PREFETCHED_MESSAGE_AMOUNT =
  'FOLLOWING_PREFETCHED_MESSAGE_AMOUNT';

export const SWAG_SOCIAL_MEDIA_LINKS = 'SWAG_SOCIAL_MEDIA_LINKS';
export const SWAG_ABOUT_LINKS = 'SWAG_ABOUT_LINKS';
export const SWAG_SUPPORT_LINKS = 'SWAG_SUPPORT_LINKS';
export const SWAG_WORK_LINKS = 'SWAG_WORK_LINKS';
export const SWAG_DISCOVER_LINKS = 'SWAG_DISCOVER_LINKS';

export const TRAILER_POST_VIDEO_LENGTH_SEC_MIN =
  'TRAILER_POST_VIDEO_LENGTH_SEC_MIN';
export const TRAILER_VIDEO_LENGTH_SEC_MIN_MAX =
  'TRAILER_VIDEO_LENGTH_SEC_MIN_MAX';
export const TRAILER_VIDEO_UPLOAD_ENABLED = 'TRAILER_VIDEO_UPLOAD_ENABLED';

export const PUSHER_STALLED_CHANNEL_CHECK_INTERVAL =
  'PUSHER_STALLED_CHANNEL_CHECK_INTERVAL';
export const PUSHER_STALLED_CHANNEL_TRACK_LIMIT =
  'PUSHER_STALLED_CHANNEL_TRACK_LIMIT';

export const FEATURE_HIDE_POST = 'FEATURE_HIDE_POST';
export const FEATURE_EDIT_POST = 'FEATURE_EDIT_POST';

export const MAX_VALID_AGE_VERIFIED_MSEC = 'MAX_VALID_AGE_VERIFIED_MSEC';

export const MIXPANEL_INIT_EVENTS = 'MIXPANEL_INIT_EVENTS';
export const MIXPANEL_RECORD_SESSIONS_PERCENT =
  'MIXPANEL_RECORD_SESSIONS_PERCENT';
export const MIXPANEL_REPLAY_RECORD_TRIGGERS =
  'MIXPANEL_REPLAY_RECORD_TRIGGERS';

export const DIAMOND_SHOP_TYPES = 'DIAMOND_SHOP_TYPES';

export const SPEEDTEST_SERVERS = 'SPEEDTEST_SERVERS';

export const FEATURE_WEB_AUTHN = 'FEATURE_WEB_AUTHN';
export const WEB_AUTHN_DESKTOP_SUPPORT_ENABLED =
  'WEB_AUTHN_DESKTOP_SUPPORT_ENABLED';
export const WEB_AUTHN_REGISTRATION_TIMEOUT_MSEC =
  'WEB_AUTHN_REGISTRATION_TIMEOUT_MSEC';
export const WEB_AUTHN_AUTHENTICATION_TIMEOUT_MSEC =
  'WEB_AUTHN_AUTHENTICATION_TIMEOUT_MSEC';

export const FEATURE_SECURIONPAY_SHOP_AGREEMENT =
  'FEATURE_SECURIONPAY_SHOP_AGREEMENT';
export const FEATURE_TAPPAY_SHOP_AGREEMENT = 'FEATURE_TAPPAY_SHOP_AGREEMENT';
export const FEATURE_ECPAY_SHOP_AGREEMENT = 'FEATURE_ECPAY_SHOP_AGREEMENT';

export const SKIP_SSR_FETCH_QUERIES = 'SKIP_SSR_FETCH_QUERIES';
export const FETCH_MESSAGE_DETAIL_CACHE_BUSTER_QUERIES =
  'FETCH_MESSAGE_DETAIL_CACHE_BUSTER_QUERIES';

export const ACQUISITION_TYPES_CREATOR_REFERRAL =
  'ACQUISITION_TYPES_CREATOR_REFERRAL';
export const ACQUISITION_TYPES_PREPAID_CARD = 'ACQUISITION_TYPES_PREPAID_CARD';
export const ACQUISITION_TYPES_USER_REFERRAL =
  'ACQUISITION_TYPES_USER_REFERRAL';
export const ACQUISITION_TYPES_REDEEM = 'ACQUISITION_TYPES_REDEEM';

export const LIVESTREAM_KARAOKE_NAME_DISPLAY_ORDER =
  'LIVESTREAM_KARAOKE_NAME_DISPLAY_ORDER';
export const LIVESTREAM_LOVENSE_NAME_DISPLAY_ORDER =
  'LIVESTREAM_LOVENSE_NAME_DISPLAY_ORDER';
export const FEATURE_SIGNUP_BIRTHDATE_INPUT = 'FEATURE_SIGNUP_BIRTHDATE_INPUT';
export const SHORTS_ADS_V2 = 'SHORTS_ADS_V2';
export const MONGO_OBJECT_ID_REGEX_PATTERN = 'MONGO_OBJECT_ID_REGEX_PATTERN';

export const PUSHER_CHANNEL_DATA_POLLING_MSEC =
  'PUSHER_CHANNEL_DATA_POLLING_MSEC';

export const FEATURE_MESSAGE_CATEGORY = 'FEATURE_MESSAGE_CATEGORY';
export const TRENDING_CATEGORY_FETCH_LIMIT = 'TRENDING_CATEGORY_FETCH_LIMIT';
export const MESSAGE_CATEGORY_SELECTION_COUNT_MAX =
  'MESSAGE_CATEGORY_SELECTION_COUNT_MAX';
export const MESSAGE_CATEGORY_SELECTION_COUNT_MIN =
  'MESSAGE_CATEGORY_SELECTION_COUNT_MIN';
export const MESSAGE_CATEGORY_GROUPINGS = 'MESSAGE_CATEGORY_GROUPINGS';
export const MESSAGE_CATEGORY_TABS = 'MESSAGE_CATEGORY_TABS';

export const CATEGORY_FEED_STORY = 'CATEGORY_FEED_STORY';
export const CATEGORY_FEED_STORY_SORTINGS = 'CATEGORY_FEED_STORY_SORTINGS';
export const CATEGORY_FEED_POST_VIDEO = 'CATEGORY_FEED_POST_VIDEO';
export const CATEGORY_FEED_POST_VIDEO_SORTINGS =
  'CATEGORY_FEED_POST_VIDEO_SORTINGS';
export const CATEGORY_FEED_POST_IMAGE = 'CATEGORY_FEED_POST_IMAGE';
export const CATEGORY_FEED_POST_IMAGE_SORTINGS =
  'CATEGORY_FEED_POST_IMAGE_SORTINGS';
export const CATEGORY_FEED_SHORT = 'CATEGORY_FEED_SHORT';
export const CATEGORY_FEED_SHORT_SORTINGS = 'CATEGORY_FEED_SHORT_SORTINGS';
export const CATEGORY_FEED_ALL = 'CATEGORY_FEED_ALL';

export const FEATURE_EXPIRE_POST = 'FEATURE_EXPIRE_POST';
export const FEATURE_EXPIRE_EDIT_POST = 'FEATURE_EXPIRE_EDIT_POST';

export const EARLYBIRD_DISPLAY_TICKET_DIAMOND =
  'EARLYBIRD_DISPLAY_TICKET_DIAMOND';
export const SHOW_DISPLAY_TICKET_DIAMOND = 'SHOW_DISPLAY_TICKET_DIAMOND';

export const BADGE_OEMBED_TEMPLATE_ID = 'BADGE_OEMBED_TEMPLATE_ID';
export const FEATURE_MINI_PROFILE = 'FEATURE_MINI_PROFILE';
export const HEADER_LOGO_URL = 'HEADER_LOGO_URL';
export const HAMBURGER_LOGO_URL = 'HAMBURGER_LOGO_URL';
export const FEATURE_SETTINGS_REDEEM = 'FEATURE_SETTINGS_REDEEM';
export const FEATURE_SETTINGS_ABOUT_SWAG = 'FEATURE_SETTINGS_ABOUT_SWAG';
export const RTA_URL = 'RTA_URL';
export const CUSTOM_RESOURCE_MAX_CACHE_SIZE = 'CUSTOM_RESOURCE_MAX_CACHE_SIZE';
export const CUSTOM_RESOURCE_CACHE_TTL_MS = 'CUSTOM_RESOURCE_CACHE_TTL_MS';
export const FAVICON_URL = 'FAVICON_URL';
export const ICON_PNG_URL = 'ICON_PNG_URL';
export const ICON_SVG_URL = 'ICON_SVG_URL';
export const APPLE_ICON_URL = 'APPLE_ICON_URL';
export const FEATURE_PWA = 'FEATURE_PWA';
export const BRANDING_NAME = 'BRANDING_NAME';
export const FEATURE_SEO = 'FEATURE_SEO';

export const SHOP_AD_OEMBED_TEMPLATE_ID = 'SHOP_AD_OEMBED_TEMPLATE_ID';

export const FEATURE_EMAIL_BINDING_TOASTR = 'FEATURE_EMAIL_BINDING_TOASTR';

export const FEATURE_RESOURCE_DECRYPTION = 'FEATURE_RESOURCE_DECRYPTION';
export const DECRYPTED_RESOURCE_CACHE_SIZE = 'DECRYPTED_RESOURCE_CACHE_SIZE';

export const IMPRESSION_TRACKING_VISIBILITY_RATIO =
  'IMPRESSION_TRACKING_VISIBILITY_RATIO';
export const IMPRESSION_TRACKING_DURATION_SECONDS =
  'IMPRESSION_TRACKING_DURATION_SECONDS';
export const RESEND_PHONE_VERIFIED_CODE_SEC = 'RESEND_PHONE_VERIFIED_CODE_SEC';
export const RESEND_EMAIL_VERIFIED_CODE_SEC = 'RESEND_EMAIL_VERIFIED_CODE_SEC';

export const INTERCOM_ID = 'INTERCOM_ID';
export const INTERCOM_UPDATE_DEBOUNCE_MSEC = 'INTERCOM_UPDATE_DEBOUNCE_MSEC';

export const ZENDESK_KEY = 'ZENDESK_KEY';

export const LIVESTREAM_CHAT_SCROLL_DETECT_ITEM_COUNT =
  'LIVESTREAM_CHAT_SCROLL_DETECT_ITEM_COUNT';
export const SCROLL_MEMORY_DISABLED_LIST = 'SCROLL_MEMORY_DISABLED_LIST';

export const PAGINATION_BUTTON_COUNT = 'PAGINATION_BUTTON_COUNT';
export const PAGINATION_BUTTON_COUNT_ON_MOBILE =
  'PAGINATION_BUTTON_COUNT_ON_MOBILE';
export const PAGINATION_ITEM_COUNT_PER_PAGE = 'PAGINATION_ITEM_COUNT_PER_PAGE';
export const PAGINATION_ITEM_COUNT_PER_ROW = 'PAGINATION_ITEM_COUNT_PER_ROW';
export const SEO_X_DEFAULT_LANG = 'SEO_X_DEFAULT_LANG';
export const SEO_STUFFING_ARTICLE_MAX_SECTIONS_COUNT =
  'SEO_STUFFING_ARTICLE_MAX_SECTIONS_COUNT';

export const API_JITTER_MSEC = 'API_JITTER_MSEC';

export const TRIGGER_PRIVATE_WAITING_TIME = 'TRIGGER_PRIVATE_WAITING_TIME';
export const TRIGGER_PRIVATE_COOLDOWN = 'TRIGGER_PRIVATE_COOLDOWN';

export const TRIGGER_EXCLUSIVE_HOLDINGTIME = 'TRIGGER_EXCLUSIVE_HOLDINGTIME';
export const TRIGGER_EXCLUSIVE_WAITING_TIME = 'TRIGGER_EXCLUSIVE_WAITING_TIME';

export const SWAG_SUPPORT_URL = 'SWAG_SUPPORT_URL';

export const LANDING_MAIN_HEADER_IMAGE = 'LANDING_MAIN_HEADER_IMAGE';
export const LANDING_SECTION1_IMAGE = 'LANDING_SECTION1_IMAGE';
export const LANDING_SECTION2_IMAGE = 'LANDING_SECTION2_IMAGE';
export const LANDING_SECTION3_IMAGE = 'LANDING_SECTION3_IMAGE';

export const AGE_VERIFIER_COOLDOWN_MSEC = 'AGE_VERIFIER_COOLDOWN_MSEC';

export const ENABLE_WEB_VITALS = 'ENABLE_WEB_VITALS';

export const LIVESTREAM_CUE_CARD_DISPLAY_DURATION_SECONDS =
  'LIVESTREAM_CUE_CARD_DISPLAY_DURATION_SECONDS';

export const HOTJAR_SITE_ID = 'HOTJAR_SITE_ID';
export const HOTJAR_VERSION = 'HOTJAR_VERSION';
export const ENABLE_HOTJAR = 'ENABLE_HOTJAR';
export const FEATURE_LIVESTREAM_SANPSHOT = 'FEATURE_LIVESTREAM_SANPSHOT';

export const LIVESTREAM_RATING_QUANTIZATION_STEP_SIZE =
  'LIVESTREAM_RATING_QUANTIZATION_STEP_SIZE';
export const LIVESTREAM_RATING_HINT_DURATION_SECONDS =
  'LIVESTREAM_RATING_HINT_DURATION_SECONDS';
export const FEATURE_LIVESTREAM_RATING = 'FEATURE_LIVESTREAM_RATING';
export const FEATURE_LIVESTREAM_RATED_MESSAGE =
  'FEATURE_LIVESTREAM_RATED_MESSAGE';
export const LIVESTREAM_RATING_HIGHLIGHT_THRESHOLD =
  'LIVESTREAM_RATING_HIGHLIGHT_THRESHOLD';
export const POST_RATING_HIGHLIGHT_THRESHOLD =
  'POST_RATING_HIGHLIGHT_THRESHOLD';
export const FEATURE_LIVESTREAM_CARD_FUNDING_PROGRESS_RATIO =
  'FEATURE_LIVESTREAM_CARD_FUNDING_PROGRESS_RATIO';
